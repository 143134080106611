
@media (max-width: 768px) {
    .result-bar h4{
    font-size: 16px;
    font-weight: 600;
    }

.jobs-nav {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
.jobs-box img{
margin: 0px 0px 15px 15px;
}
.company-info {
    margin-left: 0rem;
}
.company-info h4 {
    margin-top: -7px;
    font-weight: 700;
    font-size: 16px;
}
}