

/* Hero Section - Home Page
------------------------------*/
.hero {
    --default-color: #ffffff;
    --default-color-rgb: 255, 255, 255;
    --background-color: #000000;
    --background-color-rgb: 0, 0, 0;
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 160px 0 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1;
  }
  
  /*.hero:before {
    content: "";
    background: rgba(var(--background-color-rgb), 0.5);
    position: absolute;
    inset: 0;
    z-index: 2;
  }*/
  
  .hero .container {
    position: relative;
    z-index: 3;
  }
  
  .hero h2 {
    color: #2f2f2f;
    margin: 0;
    font-size: 44px;
    font-weight: 700;
  }
  
  .hero p {
    color: #2f2f2f;
    margin: 5px 0 15px 0;
    font-size: 20px;
  }
  
  .hero span {
    color: #686868;
    margin: 5px 0 0 10px;
    font-size: 14px;
  }
  
  .hero .sign-up-form {
    margin-top: 20px;
    padding: 10px;
    border-radius: 7px;
    background: #fff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
  }
  
  
  .hero .sign-up-form .d-flex {
    align-items: center;
    justify-content: space-between;
  }
  .hero .sign-up-form .btn-primary {
    color: #fff;
    background: var(--primary-color);
    font-size: 15px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
   
  }
  
  .hero .sign-up-form .form-control {
    border: none;
  }
  
  .hero .sign-up-form .form-control:active,
  .hero .sign-up-form .form-control:focus {
    outline: none;
    box-shadow: none;
  }
  
  .hero .sign-up-form input[type=submit] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    padding: 8px 20px 10px 20px;
    border-radius: 7px;
    color: var(--contrast-color);
    align-self: center;
    height: 48px;
  }
  
  .hero .sign-up-form input[type=submit]:hover {
    background-color: rgba(var(--primary-color-rgb), 0.9);
  }
  
  /* Style the custom select container */
  .custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  /* Style the select element */
  .custom-select select {
    display: block;
    width: 100% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  
  /* Style the dropdown icon */
  .select-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 1rem;
    color: #000;
  }
  
  .homepage-filter{
    display: flex;
    align-items: center;

}


/************************************ Banner section ******************************* */
  
  @media (max-width: 768px) {
    .homepage-filter{

        flex-wrap: wrap;
    }
    
    .filter-role{
        width: 95%;
    }
    .filter-Location{
        width: 165px;
    }
 .filter-JobType{
    width: 120px;
 }
 .filter-role, .filter-Location, .filter-JobType{
   margin: 10px 2px;
   font-size: 12px !important;
}
.hero span {
    color: #686868;
    margin: 5px 0 0 10px;
    font-size: 12px;
}
.hero .sign-up-form .form-control {
    border: var(--bs-border-width) solid var(--bs-border-color);
}
.custom-select select {

    font-size: 14px;
    
}
    .hero h2 {
      font-size: 32px;
    }
  
    .hero p {
      font-size: 18px;
    }
  }
  
/************************************ jobcategory ******************************* */


/* jobcategory Section - Home Page
------------------------------*/
.jobcategory .jobcategory-item {
    display: flex;
    margin: 0rem 1rem;
    justify-content: left;
    padding: 1.2rem;
    background: #F5F5F5;
    position: relative;
    padding-top: 40px;
  }
  
  /*.jobcategory .jobcategory-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: rgba(var(--default-color-rgb), 0.1);
  }
  */
  /*.jobcategory .jobcategory-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 2px;
    background: var(--primary-color);
    border-right: 5px solid var(--background-color);
  }*/
  
  /* .jobcategory a.explore {
    color: #;
  } */
  
  .jobcategory span {
    font-weight: 600;
    font-size: 14px;
    color: #14A077;
  }
  
  .jobcategory .jobcategory-item .icon {
    width: 48px;
    height: 48px;
    position: relative;
    margin-right: 25px;
    line-height: 0;
  }
  
  .jobcategory .jobcategory-item .icon i {
    color: rgba(var(--default-color-rgb), 0.7);
    font-size: 56px;
    transition: ease-in-out 0.3s;
    z-index: 2;
    position: relative;
  }
  
  /*.jobcategory .jobcategory-item .icon:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    background: rgba(var(--primary-color-rgb), 0.3);
    border-radius: 50px;
    z-index: 1;
    bottom: -15px;
    right: -15px;
    transition: 0.3s;
  }
  */
  .jobcategory .jobcategory-item .title {
    font-weight: 700;
    font-size: 18px;
  }
  
  .jobcategory .jobcategory-item .title a {
    color: var(--secondary-color);
  }
  
  .jobcategory .jobcategory-item .title a:hover {
    color: var(--primary-color);
  }
  
  .jobcategory .jobcategory-item .description {
    line-height: 24px;
    margin-bottom: 2px;
    font-size: 14px;
  }


  @media (max-width: 768px) {

  .jobcategory .jobcategory-item {
    text-align: center;
    display: flex;
    padding: 10px;
    margin: 10px 0px;
  }
  .jobcategory .col-6 {
    padding:  0px 5px;
  }
  .jobcategory .jobcategory-item .title {
    font-weight: 700;
    font-size: 14px;
    margin: 5px 0px;
}


}




/* Newjoblisting - Home Page
------------------------------*/
.job-posts-container {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    text-align: left;
    width: 80%;
    border-top: 1px solid #ccc;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin: 20px auto;
  }
  
  .job-posts-container a {
    border-bottom: 1px solid #ccc;
  }
  
  .job-posts-container a:last-child {
    border-bottom: none;
    /* Remove border for the last item */
  }
  
  
  
  .job-posts-container h3 {
    margin-left: -8rem;
    /* width: 5rem; */
    width: 20rem;
    font-size: 18px;
    font-weight: 600;
  }

  .Newjoblisting {
    border-bottom: 1px solid #ccc !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 5px;
  }
  
  .Newjoblisting:last-child {
    border-bottom: none;
    /* Remove border for the last item */
  }
  
  .Newjoblisting-items {
    
    margin-left: 10px;
    padding: 10px;
  }

  .Newjoblisting-items h3{
    
    margin-left: 10px;

  }
  .Newjoblisting-items:nth-child(1) {
    align-items: center;
    display: flex;
  }
  
  .Newjoblisting-items .btn-apply {
    background-color: #00BF58;
    padding: 8px 20px 10px 20px;
    border-radius: 50px;
    color: var(--contrast-color);
    align-self: center;
    height: 48px;
  }
  
  .Newjoblisting-items .btn-apply:hover {}
  
  
  .icon-text-section {
    display: flex;
    align-items: center;
    /* Vertically center content */
    padding: 15px 30px;
    /* Adjust padding as needed */
  
  }
  
  .iconicon {
    flex: 0 0 auto;
    /* Don't allow this div to grow or shrink */
    margin-right: 20px;
    /* Adjust margin as needed for spacing between icon and text */
  }
  
  .iconicon img {
    max-width: 50px;
    /* Adjust the max-width of the icon as needed */
  }
  
  .icontext {
    flex: 1;
    /* Allow this div to grow and take up remaining space */
  }
  
  .icontext h4 {
    font-size: 18px;
    padding-bottom: 0.7rem;
    margin-bottom: 1rem;
    font-weight: 700;
    margin: 0;
    /* Remove default margin for heading */
  }
  
  .icontext p {
    margin: 0;
    /* Remove default margin for paragraphs */
  }
  .job-posts-container h3 {
    margin-left: 1rem;
}
  
  @media (max-width: 768px) {
    .job-posts-container {
        width: 100%;
    }
    .Newjoblisting {
        display: flex;
        flex-wrap: wrap;
    }
    .job-posts-container h3 {
        margin-left: 1rem;
    }
  }