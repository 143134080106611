.loginregister{
    margin-top: 100px;
}
.submit{
    background: var(--primary-color);
    color: var(--contrast-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 4px;
}
.field{
    margin:20px 0px;
}
.loginregisterimg {
    background-image: url(../../../img/A1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom	;
    height: auto;
  }
  
.forgotpassword{
    // margin-left: -50px;
    font-size: 14px;
    line-height: 25px;
  }

.loginregister {
    
}

      .loginregisterimg {
        padding: 50px 10px;
        background-image: url(../../../img/m-A1.jpg);
        height: auto;
      }
