.loginregister{
    margin-top: 100px;
    overflow-y: auto;
    
}

.submit{
    background: var(--primary-color);
    color: var(--contrast-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 4px;
}
.otp_field{
    margin: 0px 20px;
    width: 10%;
}
.companyloginregisterimg {
    background-image: url(../../../img/companylogin-page.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
  }
  .companyTypebar{
    background-color: #FFF;
    border-radius: 25px;
  }
  .quill {
    background: #fff;
}
.searchWrapper {
  background: #fff;}
.nextsubmit{
  padding: 10px 50px !important;
}

@media (max-width: 778px) {
    .Dynamic-data {
        display: none;
    }
    .companyloginregisterimg{
    padding: 50px 10px;
    background-image: url(../../../img/m-A1.jpg);
    height: auto;
}
.companyloginregisterimg h4{
    color: #fff;
  }
  .companyloginregisterimg a{
    color: #fff;
    font-size: 12px;
  }
  .continuewith{
    color: #fff;
  }


}