.accordion-button:not(.collapsed) {
    background-color: #fff;
    box-shadow: none;
    color: #43c5ff;
  }
  .process .accordion-button{
    font-size: 20px;
    font-weight: 500;
    padding: 1rem 1rem 1rem 0rem;
  }
  .process .accordion-body {
    padding: 0;
    margin-bottom: 2rem;
  }
  
  .process .accordion-item {
    border-right: #fff;
    border-left: #fff;
    border-top: #fff;
    border-bottom: 1px solid #cafeff !important;
  }
  
  .accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    box-shadow: none;
  }