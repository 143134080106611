.loginregister{
    margin-top: 100px;
}
.submit{
    background: var(--primary-color);
    color: var(--contrast-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 4px;
}
.otp_field{
    margin: 0px 20px;
    width: 10%;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border-radius: 25px;
}
.input-align{
    margin-top: 5px;
}
// chnages done
.countryinputs {
    margin: 10px 0px 0px -10px;
}
// :where(.css-dev-only-do-not-override-pr0fja).ant-upload-wrapper {
//     border: 1px solid #ddd;
//     border-radius: 20px;
//     box-sizing: border-box;
//     padding: 3px 15px;
// }
.Uploadtheresume :where(.css-dev-only-do-not-override-pr0fja).ant-btn-default {
    border: 1px solid #ddd;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 3px 15px;
    width: 210px;
    height: 40px;
}

@media (max-width: 785px){
  .Uploadtheresume  :where(.css-dev-only-do-not-override-pr0fja).ant-btn-default {
    width: 180px !important;
}
}
.error-input{
 font: 12px;
 margin: 0;
 color: #cf3737;
}

