

.loginregister{
    margin-top: 100px;
}
.submit{
    background: var(--primary-color);
    color: var(--contrast-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 4px;
}
.field{
    margin:20px 0px;
}

.companyloginregisterimg {
    background-image: url(../../../img/companylogin-page.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
  }

@media (max-width: 778px) {
    .companyloginregisterimg{
    padding: 50px 10px;
    background-image: url(../../../img/companylogin-page.jpg);
    height: auto;
}
.companyloginregisterimg h4{
    color: #fff;
  }
  .companyloginregisterimg a{
    color: #fff;
    font-size: 12px;
  }
}