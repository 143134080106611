/* Color palette */
$primary-color: #cfa24d;
$secondary-color: #8993a3;
$light-white: #ffffff40;
$primary-light-color: #0d494e10;
$primary-black: #42526e;
$secondary-black: #122241;
$primary-grey: #c4c7cf;
$dark-blue: #22509d;
$light-blue: #4f7cd3;
$light-grey: #00000015;
$dark-grey: #8898aa;
$success-color: green;
$danger-color: #fe0000;
$warning-color: #f3b21b;
$body-bg: #f5f8fa;
$table-bg: #f7fafc;
$input-bg: #f8fbfc;
$border-color: #e1e3e7;
$navbar-color: #0f1728;
$row-hover: #f6f6f7;
$success-color: #55bc79;
$btn-ongoing: #b2eafd;
$btn-ongoing-color: #21738a;
$btn-void: #ffc1d4;
$btn-void-color: #c92b3e;
$btn-closed: #abf5d3;
$btn-closed-color: #056344;
$btn-invoiced: #b2eafd;
$btn-invoiced-color: #21738a;
$btn-green: #abf5d3;
$btn-green-color: #056344;
$btn-pending: #fff1b4;
$btn-pending-color: #e89319;
$btn-pending-dark: #ffe6b7;
$background-row-light-yellow: #fff9ed;
$release-amount: #ed4e44;
$alert-icon: #c8583e;
$warning-border: #dee0e4;
$final-price-back: #323e53;
$final-price-back-shadow: #00000008;
$white-without-alpha: #fff;
$box-shadow-color: #0000001a;
$tender-form-background: #fafbfc;
$checkbox-outline: #c1c4cc;
$checkbox-danger-outline: #bb5f46;
$checkbox-success-color: #55bc79;
$table-hover: #f2f6fc;
$white-color-with-alpha: #ffffff;
$yellow-search-button-color: #ddac51;
$yellow-hover-button-color: #b78c37;
$danger-hover-background: #b54930;
$default-selection: #7951d3;
$profile-icon-border: #c8a45b80;
$profile-pic-background: #7c58c0;
$camera-icon-background: #fffbf4;
$upload-hover: #d9d9d9;
$upload-hint: #6080cf;
$accordion-border: #f0f1f3;
$side-bar-backshadow: #00000005;
$side-bar-background: #f6edde;
$side-bar: #b2b6c1;
$user-blueviolet: #8a2be2;
$user-darkorange: #ff8c00;
$user-mediumvioletred: #c71585;
$user-crimson: #dc143c;
$icon-grey:#808080;
$transparent-white: #ffffff80;
$document-processing: #ffdfb4;
$document-processing-color: #da672c;

/* Table */
$table-border: #f0f0f0;
$table-cell-background: #fafafa;
$table-pagination-background: #f9f9fb;
$table-pagination-border: #c4c7cf7e;
$table-pagination-text: #898fa0;
$table-selector-border: #d5d5df;
$table-header-border: #d6d8e5;

/* Shadows */
$primary-shadow: 0 8px 22px #0000001a;
$secondary-shadow: 0px 3px 6px #2c28281c;
$auth-backshadow: 0px 12px 32px #00000014;
$placeholder-color: #c1c4cd;
$primary-button-color: #cfa24d;
$cancel-button-color: #ecedf0;
$secondary-button-color: #506078;
$danger-button-color: #c9583e;
$hover-back-shadow: 0px 1px 6px #00000010;
$tender-requested: #edeff3;
$tender-requested-color: #818b98;
$tender-submitted: #eae6fe;
$tender-submitted-color: #534693;
$profile-navbar-back-shadow: #00000029;
$drawer-shadow: #0000000a;
$overlay-backshadow: #00000014;
$accordion-backshadow:#3e3f5e17;
$unread-bg: #FFFBF4;

/* Spacer variables */
$spacer-x: 1rem;
$spacer-y: 1rem;
