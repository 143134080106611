@import "src/styles/variables";

.input-field {
  display: flex;
  flex-direction: column-reverse;
  .icon-phone,
  .icon-email {
    color: $secondary-color;
  }
  .input-field__label {
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 500;
    color: $secondary-color;
  }
  .ant-input-search-button {
    background-color: $white-without-alpha;
    border: none;
    box-shadow: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 40px;
    span {
      color: $yellow-search-button-color !important;
    }
  }
  .ant-input-wrapper {
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid $border-color;
    input {
      border: none !important;
    }
    .ant-input-group-addon {
      border: none;
      background-color: transparent;
    }
  }
  .ant-input-disabled {
    background: #f6f6f7;
    box-shadow: 0px 1px 6px $final-price-back-shadow;
    border: 1px solid $border-color;
  }

  

  input, select,
  textarea{
    box-shadow: 0px 1px 6px $final-price-back-shadow;
    border: 1px solid $border-color;
    border-radius: 6px;
    // font: normal normal 600 14px/24px Gilroy;
    font-family: poppins;

    &::placeholder {
      font-weight: 600;
      color: $placeholder-color;
    }
    &:hover {
      box-shadow: $hover-back-shadow;
      border: 1px solid $border-color;
    }
    &:focus {
      border: 1px solid $primary-color;
      + .input-field__label {
        color: $primary-color;
      }
    }
  }
  input {
    height: 40px;
  }
  textarea {
    height: auto;
    min-height: 80px;
  }

  .input__error {
    margin-top: 6px;
    color: $danger-button-color;
    + input,
    + textarea,
    + .ant-input-group-wrapper {
      border: 1px solid $checkbox-danger-outline;
    }
  }
  .ant-input-group-wrapper {
    border-radius: 6px;
  }
}
