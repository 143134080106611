
.jobdetailssidenav{
    background-color: #e5f1f2;
    padding: 1rem;
    position: sticky;
    top: 7rem;
}

@media (max-width: 778px) {
    .jobdetailssidenav{
        background-color: #e5f1f2;
        padding: 1rem;
        position: relative;
    }

    .jobs-nav{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates 3 columns */
    }
.jobs-nav-item{
    display: flex;
    align-items: center;
    text-align: left;
    margin:0rem 0rem 1rem 0rem;
}
.jobs-nav-item img{
   margin-right: 10px;
}
.jobs-nav-item a{
font-size: 14px;
font-weight: 600;
 }
 a.loginbtn {
    margin-top: 1rem;
    border-radius: 5px;
    padding: 11px 31px;
    margin: 18px 10px;
    background: #43c5ff;
    color: #ffffff;
}
}