.sidewidth {
    width: 100% !important;
}
.jobapplicant{
    position: sticky;
    background-color: #e5f1f2;
    padding: 1rem;
    top: 7rem;
}
@media (max-width: 778px){
    .companyprofile-info img {
        width: 100px;
        margin-bottom: 20px;
    }

    .jobapplicant{
        position: relative;
    
    }

/* .sidewidth a.loginbtn {
    bottom: 0 !important;
    position: absolute;
} */
}
