
/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.navmobileflex{
  display: flex;
}
#cloudtechmenu{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
    --background-color: #ffffff;
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 10px 0;
    transition: all 0.5s;
    z-index: 997;
  }
  
  .header .logo img {
    max-height: 50px;
    margin-right: 6px;
  }
  
  .header .logo h1 {
    font-size: 24px;
    margin: 0;
    font-weight: 600;
    color: var(--secondary-color);
  }
  
  .header .logo span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 600;
    padding-left: 3px;
  }
  
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    color: var(--contrast-color);
    background: var(--primary-color);
    font-size: 14px !important;
    padding: 8px 26px;
    margin: 0;
    border-radius: 4px;
    transition: 0.3s;
  }
  
  .header .btn-getstarted:hover,
  .header .btn-getstarted:focus:hover {
    color: var(--contrast-color);
    background: rgba(var(--primary-color-rgb), 0.85);
  }
  
  @media (max-width: 1200px) {
    .header .logo {
      order: 1;
    }
  
    .header .btn-getstarted {
      order: 2;
      margin: 0 15px 0 0;
      padding: 6px 20px;
    }
  
    .header .navmenu {
      order: 3;
    }
  }
  
  /* Global Header on page scroll
  ------------------------------*/
  .scrolled .header {
    --background-color: #ffffff;
    --secondary-color: #444444;
    --nav-color: #444444;
    --nav-hover-color: #43C5FF;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
  }

/* Home Page Custom Header
------------------------------*/
.index-page .header {
    --background-color: #ffffff;
    --secondary-color: #ffffff;
    --nav-color: rgba(255, 255, 255, 0.515);
    --nav-hover-color: #ffffff;
  }
  
  /* Home Page Custom Header on page scroll
  ------------------------------*/
  .index-page.scrolled .header {
    --background-color: #ffffff;
    --secondary-color: #444444;
    --nav-color: #444444;
    --nav-hover-color: #d83535;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
      display: none;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: #262626;
      padding: 18px 20px;
      font-size: 16px;
      font-weight: 700;
      font-family: poppins;
      /* font-family: var(--nav-font); */
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    /* .navmenu li:last-child a {
      padding-right: 0;
    } */
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      color: #262626;
    }
  
    .navmenu .active {
      display: block;
    }
    
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  
    .navmenu .megamenu {
      position: static;
    }
  
    .navmenu .megamenu ul {
      margin: 0;
      padding: 10px;
      background: var(--nav-dropdown-background-color);
      box-shadow: 0px 0px 20px rgba(var(--default-color-rgb), 0.1);
      display: block;
      position: absolute;
      top: 130%;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      display: flex;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
    }
  
    .navmenu .megamenu ul li {
      flex: 1;
    }
  
    .navmenu .megamenu ul li a,
    .navmenu .megamenu ul li:hover>a {
      padding: 10px 20px;
      font-size: 15px;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .megamenu ul li a:hover,
    .navmenu .megamenu ul li .active,
    .navmenu .megamenu ul li .active:hover {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .megamenu:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dd-box-shadow {
      box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.15);
    }
  }
  
  @media (min-width: 992px) {
    .navmenu {
      display: block;
    }
  }
  /* Mobile Navigation */

/* Mobile Navigation */
/* @media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: #000;
    font-size: 35px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
    display:none;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    height: 250px;
    display: block;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 0px;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.1);
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: rgba(var(--primary-color-rgb), 0.1);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--primary-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--primary-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul,
  .navmenu .megamenu ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul,
  .navmenu .megamenu ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown>.dropdown-active,
  .navmenu .megamenu>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 40px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu>ul {
    display: block;
  }
} */

/*mobile menu*/

  .profile-nav img{
    width: 45px !important;
    height: auto;
    border-radius: 23px;
    margin-right: 10px;
  }
  
@media screen and (max-width: 1200px) {
.profile-nav img{
  width: 45px !important;
  height: auto;
}

  .profile-name-img{
    width: 45px !important;
    height: auto;
    border-radius: 50px;
    margin-right: 10px;
    }


  .mobile-nav-toggle {
      display: block; 
  }

  .navmenu {
      display: none; 
      position: absolute; 
      top: 0;
      right: 0;
      width: 100%; 
      background-color: #fff; 
  }

  .navmenu.active {
      display: block; 
  }

  .navmenu ul {
      display: block;
      text-align: center;
      box-shadow: 10px 0 39px -12px rgba(0, 0, 0, 0.1);
  }

  .navmenu ul li {
      display: block;
      margin: 10px 0;
      font-size: 14px;
  }
  .navmenu ul li a{
    color: #262626;
}
.navmenu ul li a:hover{
  color: #43C5FF;
}

}


    .mobileprofile-view{
        display: none !important;
    }
    .desktopprofile-view{
        display: block !important;
    }
        

  
    .profile-nav .profile-name{
     font-weight: 600;
     font-size: 18px;
      }

 /*                                 profile view                 */




      
    @media (max-width: 1200px) {

      .mobileprofile-view{
        display: block !important;
    }
    .desktopprofile-view{
        display: none !important;
    }
      


      .mobile-nav-toggle {
        margin-left: 22px;
    }
      .navloginicon {

        border-radius: 38px;
        background-color: #ffffff;
        color: #43c5ff;
        border: 1px solid #43c5ff;
        width: 38px;
        height: auto;
        padding: 5px;
      }
      ul.mloginout{
        margin-bottom: -7px;
        
       }
     ul.loginmenu{
      list-style: none;
    background-color: #fff;
    position: absolute;
    top: 60px;
    right: 47px;
    display: none;
     } 

     ul.loginmenu.active{
      list-style: none;
    background-color: #fff;
    position: absolute;
    top: 60px;
    right: 0px;
    display: block;
     } 

     ul.loginmenu li {
      margin-left: -30px;
      color: var(--nav-dropdown-color);
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
  }

       .mobile-view{
        display: flex;
        align-items: center;
        justify-content: center;
       }


      .navmenu .dropdown ul, .navmenu .megamenu ul {
        position: static;
        /* display: block; */
      }

      #cloudtechmenu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* flex-direction: row-reverse; */
    }
      .header .logo img {
        padding: 6px;
        margin-left: 50px;
      }
        .mobileprofile-view{
            display:block ;
        }
        .desktopprofile-view{
            display:none ;
        }
        .navmenu ul {
          padding: 15px 25px;
          text-align: left;
          list-style: none;
          background-color: #fff;
          position: absolute;
          top: 75px;
          left: 10px;
            
          }  
          
          ul.mloginout{
           list-style: none;
          }
    }
   .rowaction{
    display: flex;
    flex-direction: row-reverse;
   }