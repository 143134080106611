.stickyposition:where(.css-dev-only-do-not-override-pr0fja).ant-btn-default {
    width: 306px;
}



@media (max-width: 758px){
.side-nav {
    top: 17rem !important;
}
}