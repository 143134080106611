.borderradius{
    border-radius: 50px !important;
}
.input-field .ant-input-disabled {
    background: #f6f6f7;
    border-radius: 50px;}
    .input-field input {
        height: 40px;
        border-radius: 50px;}

        @media (max-width: 785px) {
        :where(.css-dev-only-do-not-override-pr0fja).ant-btn-default {
            width: 365px;}
        }